import UtilsEntityView from "./entityView";
import Utils from "./index";

export default class UtilsErp {

    static async initOrder() {
        let url = `/api/v1/erp/actions/order_init`
        const r = await UtilsErp.request(url, "POST")
        return r;
    }

    static async saveOrder(token: any) {
        let url = `/api/v1/erp/actions/order_save`
        const r = await UtilsErp.request(url, "POST", {
            token: token
        })
        return r;
    }

    static async customerOrder(customerId: any, token: any) {
        let url = `/api/v1/erp/actions/order_customer`
        const r = await UtilsErp.request(url, "POST", {
            customerId: customerId,
            token: token
        })
    }
    static async addLineItemOrder(data: any, token: any) {
        let url = `/api/v1/erp/actions/order_add_line_item`
        const r = await UtilsErp.request(url, "POST", {
            lineItem: data,
            token: token
        })
        return r;
    }

    static async downloadLabel(id: any, entity: string) {
        await Utils.showLoader();
        let url = `/api/v1/erp/actions/create_labels`
        const r = await UtilsErp.request(url, 'POST', {
            entity: entity,
            ids: [id]
        })
        await Utils.hideLoader();
        return r;
    }


    static async request(url: string, method: string, body = {}, plaintext = false) {
        const init = {
            method: method,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        if (method !== "GET" && method !== "DELETE") {
            if (!plaintext) {
                // @ts-ignore
                init.body = JSON.stringify(body)
            } else {
                // @ts-ignore
                init.body = body
            }
        }
        const r = await fetch(url, init)
        return await r.json()
    }
}