import UtilsEntity from "./entity";
import UtilsErp from "./erp";
import UtilsTimeTrack from "./timetrack";

export default class Utils {
    static get entity() {
        return UtilsEntity
    }

    static get erp() {
        return UtilsErp
    }

    static get timeTrack() {
        return UtilsTimeTrack
    }

    static base64ToArrayBuffer(base64: string) {
        const binaryString = window.atob(base64);
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    }



    static async download(content: any, filename: any) {
        const a = document.createElement('a');
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(content);
        a.href = url;
        a.download = filename;
        a.click();
        setTimeout(() => {
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }, 0)
    }
    static createAndDownloadBlobFile(body: any, filename: string, extension = 'pdf') {
        const blob = new Blob([body]);
        const fileName = `${filename}.${extension}`;
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    static async getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        })
    }

    static async createSelects(data: any, onFilterRequest = (elem: any) => {return {}}, onFilterResults = (data: any, entity: any) => {return data}, selector = ".content-wrapper .select2") {
        document.querySelectorAll(selector).forEach((element) => {
            let selected_key = null;
            let selected_value = null;

            let name_path: string | null = null;
            let id_path: string | null = null;
            if (element.getAttribute("data-selected-key")) {
                selected_key = Utils.getValueByPath(data, element.getAttribute("data-selected-key"))
            }
            if (element.getAttribute("data-selected-value")) {
                selected_value = Utils.getValueByPath(data, element.getAttribute("data-selected-value"))
            }
            if (element.getAttribute("data-name-path")) {
                name_path = element.getAttribute("data-name-path")
            }
            if (element.getAttribute("data-id-path")) {
                id_path = element.getAttribute("data-id-path")
            }
            if (selected_key && selected_value) {
                element.innerHTML = `<option value='${selected_key}' selected>${selected_value}</option>`
            }
            const entity = element.getAttribute("data-entity")

            let parent = document.body;
            const offcanvasParent: any = element.closest(".offcanvas-body");
            const modalParent: any = element.closest(".modal-body");
            if (modalParent) {
                parent = modalParent
            } else if (offcanvasParent) {
                parent = offcanvasParent
            }
            // @ts-ignore
            jQuery(element).wrap('<div class="position-relative"></div>').select2({
                dropdownParent: parent,
                placeholder: "Choose option..",
                ajax: {
                    url: `/api/v1/${entity}`,
                    data:  (params: any) => {
                        return {
                            filter: params.term,
                            ...onFilterRequest(element)
                        }
                    },
                    processResults: (data: any) => {
                        return {
                            results: onFilterResults(data.data, entity).map((d: any) => {
                                return {text: name_path ? Utils.getValueByPath(d, name_path): d.name, id: id_path ? Utils.getValueByPath(d, id_path): d.uuid, data: d}
                            })
                        };
                    }
                }
            });
        })
    }

    static getValueByPath(data: any, path: any) {
        const s = path.split(".");
        let currentValue = data;
        for (const part of s) {
            if(currentValue[part]) {
                currentValue = currentValue[part]
            } else {
                return null;
            }
        }
        return currentValue;
    }

    static async updateElements(data: any, prefix = '', parent: any = document) {
        if (!Array.isArray(data) && data) {
            Object.keys(data).forEach((column) => {
                const fullName = `${prefix}${column}`
                const value = data[column];
                if (typeof value === "object") {
                    if (Array.isArray(value)) {
                        value.forEach((v: any, i: number) => {
                            Utils.updateElements(v, `${fullName}[${i}].`)
                        })
                    } else {
                        Utils.updateElements(value, `${fullName}.`)
                    }
                } else {
                    Utils.setElement(fullName, value, parent)
                }
            })
        }
    }
    static setElement(key: string, value:string, parent: any = document) {
        //@ts-ignore
        parent.querySelectorAll(`*[data-entity-field='${key}']`).forEach((element) => {
            const applyForValue = element.getAttribute("data-entity-value");
            const pattern = element.getAttribute("data-entity-pattern");
            const target = element.getAttribute("data-entity-target");
            const formatter = element.getAttribute("data-entity-formatter");
            if (applyForValue) {
                if (applyForValue === value || applyForValue === `${value}`) {
                    element.classList.remove("d-none");
                } else {
                    element.classList.add("d-none");
                }
            } else if(pattern && target) {
                element.setAttribute(target, pattern.replace("$VAL", value))
            } else {
                if (element.nodeName === "INPUT") {
                    const inputType = element.getAttribute("type");
                    console.log("inputType", inputType, value)
                    if (inputType === "checkbox") {
                        if (value) {
                            element.setAttribute("checked", "checked")
                        } else {
                            element.removeAttribute("checked")
                        }
                    } else if(inputType === "date") {
                        const s = value.split(".")
                        const newVal = `${s[2]}-${s[1]}-${s[0]}`
                        // @ts-ignore
                        element.value = formatter ? Utils[formatter](newVal) : newVal;
                    } else if(inputType === "datetime-local") {
                        const newVal = Utils.transformDateToInput(value)
                        // @ts-ignore
                        element.value = formatter ? Utils[formatter](newVal) : newVal;
                    } else {
                        // @ts-ignore
                        element.value = formatter ? Utils[formatter](value) : value;
                    }
                } else if(element.nodeName === "SELECT" || element.nodeName === "TEXTAREA") {
                    // @ts-ignore
                    element.value = formatter ? Utils[formatter](value) : value;
                } else {
                    // @ts-ignore
                    element.innerHTML = formatter ? Utils[formatter](value) : value;
                }
            }
        })
    }


    static transformDateToInput(date: string) {
        const d = new Date(Date.parse(date))
        return (new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString()).slice(0, -1)
    }

    static formatDate(d: any) {
        return new Date(Date.parse(d)).toLocaleString("de-DE", {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        })
    }

    static translate(str: string) {
        const parts = str.split(".")
        // @ts-ignore
        let o = window.I18n
        parts.forEach((part) => {
            // @ts-ignore
            if (o[part]) {
                // @ts-ignore
                o = o[part]
            } else {
                console.warn(`Translation not found: ${str}`)
                return `Translation not found: ${str}`;
            }
        })
        if (typeof(o) === "string") {
            return o;
        } else {
            return "";
        }
    }
    static msToHMS( ms: number ) {
        let seconds: any = ms / 1000;
        let hours: any = parseInt( String(seconds / 3600) ); // 3,600 seconds in 1 hour
        seconds = seconds % 3600; // seconds remaining after extracting hours
        let minutes: any = parseInt( String(seconds / 60) ); // 60 seconds in 1 minute
        seconds = parseInt(String(seconds % 60));
        if (hours <= 9) {
            hours = `0${hours}`
        }
        if (minutes <= 9) {
            minutes = `0${minutes}`
        }
        if (seconds <= 9) {
            seconds = `0${seconds}`
        }
        return hours+":"+minutes+":"+seconds;
    }

    static async hideLoader() {
        const loader = document.querySelector("#loading-bg");
        if(loader) {
            loader.classList.add("d-none");
        }
    }
    static async showLoader() {
        const loader = document.querySelector("#loading-bg");
        if(loader) {
            loader.classList.remove("d-none");
        }
    }
    static genRandomColor() {
        return Math.floor(Math.random()*16777215).toString(16);
    }

    static makeId(length = 7) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    static secondsToHhMm(seconds: number) {
        return new Date(Math.abs(seconds) * 1000).toISOString().slice(11, 16);
    }
}